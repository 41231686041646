import { template as template_2d1a4cc3680f4020a651eaa897135dd3 } from "@ember/template-compiler";
const WelcomeHeader = template_2d1a4cc3680f4020a651eaa897135dd3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
