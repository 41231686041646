import { template as template_4e08ff5d8e6146ff929e60272cf99b91 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const TopicCell = template_4e08ff5d8e6146ff929e60272cf99b91(`
  <SortableColumn
    @order="default"
    @category={{@category}}
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name={{@name}}
    @bulkSelectEnabled={{@bulkSelectEnabled}}
    @showBulkToggle={{@showBulkToggle}}
    @canBulkSelect={{@canBulkSelect}}
    @canDoBulkActions={{@canDoBulkActions}}
    @showTopicsAndRepliesToggle={{@showTopicsAndRepliesToggle}}
    @newListSubset={{@newListSubset}}
    @newRepliesCount={{@newRepliesCount}}
    @newTopicsCount={{@newTopicsCount}}
    @bulkSelectHelper={{@bulkSelectHelper}}
    @changeNewListSubset={{@changeNewListSubset}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TopicCell;
