import { template as template_e63b29c030714e549520d2d54d8d675f } from "@ember/template-compiler";
const FKLabel = template_e63b29c030714e549520d2d54d8d675f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
