import { template as template_ef6ba57f18a5412586b4b2390499951a } from "@ember/template-compiler";
const FKControlMenuContainer = template_ef6ba57f18a5412586b4b2390499951a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
